(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("xgplayer"));
	else if(typeof define === 'function' && define.amd)
		define(["xgplayer"], factory);
	else if(typeof exports === 'object')
		exports["xgplayer-mp4"] = factory(require("xgplayer"));
	else
		root["xgplayer-mp4"] = factory(root["xgplayer"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__3__) {
return 